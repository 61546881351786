<template>
  <div>
    <router-link class="come-back" to="/admin/polls">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование Опроса' : 'Создание Опроса' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createPolls" ref="addPolls">
      <div>
        <div class="content-sb">
          <p>Ссылка на Яндекс.Формы</p>
          <a class="link-yandex-form" href="https://yandex.ru/forms/">https://yandex.ru/forms/</a>
        </div>
        <div class="content-sb">
          <p>Заголовок</p>
          <input  placeholder="Введите название страны" type="text" name="title" v-model="form.title" class="input blue">
        </div>
        <div class="content-sb">
          <p>Заголовок (Англ)</p>
          <input placeholder="Введите заголовок (Англ)" v-model="form.title_eng" type="text" name="title_eng" class="input blue">
        </div>
        <div class="content-sb">
          <p>Описание</p>
          <input  placeholder="Описание опроса" type="text" v-model="form.description" name="description" class="input blue">
        </div>
        <div class="content-sb">
          <p>Описание (Англ)</p>
          <input placeholder="Введите описание (Англ)" v-model="form.description_eng" type="text" name="description_eng" class="input blue">
        </div>
        <div class="content-sb">
          <p>Ссылка на яндекс форму</p>
          <input  placeholder="Вставьте ссылку на яндекс форму" type="text" name="externalForm" v-model="form.externalForm" class="input blue">
        </div>

        <div class="content-sb">
          <p>Дата</p>
          <input  placeholder="Вставьте ссылку на яндекс форму" type="datetime-local" name="date" v-model="form.date" class="input blue">
        </div>

        <div class="content-sb">
          <p>Теги</p>
          <tags @dataTags="dataTags" :startValue="form.tagList"/>
        </div>

      </div>
      <footer class="content-sb btns-create-form">
        <router-link to="/admin/polls" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" :value="$route.query.type === 'edit' ? 'Изменить' : 'Сохранить'"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
import Tags from '@/components/Admin/Tags'

export default {
  name: 'createPolls',
  data () {
    return {
      form: this.$route.query.type === 'edit' ? this.$store.getters.getDetailPolls : {
        title: '',
        tagList: [],
        description: '',
        externalForm: '',
        title_eng: '',
        description_eng: '',
        date: ''
      }
    }
  },
  methods: {
    createPolls () {
      const formData = new FormData()
      for (var key in this.form) {
        if (!!this.form[key] && this.form[key].length !== 0) {
          (key === 'tagList') ? formData.append('tagList', JSON.stringify(this.form.tagList)) : formData.append(key, this.form[key])
        }
      }
      if (this.$route.query.type === 'edit') {
        this.$store.dispatch('editPolls', { id: this.form.id, data: formData })
      } else {
        this.$store.dispatch('createPolls', formData)
      }
    },
    dataTags (value) {
      this.form.tagList = value
    }
  },
  mounted () {
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailPolls === null) {
      this.$router.push('/admin/polls')
    }
    // this.form.date = this.$store.getters.getDetailPolls.date.split(' ').join('T')
  },
  components: {
    TricolorLine,
    Tags
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.link-yandex-form{
  font-weight: 600;
  font-size: .813rem;
  line-height: 1.25rem;
  color: #246CB7;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
